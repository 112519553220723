<template>
    <div>
    <v-container>
        <v-row class='mt-10 flex-column align-center'>
        <div>
          <v-col cols='12' sm='6' md='12'>
            <v-img
              max-height='270'
              max-width='270'
              src='../assets/Mendoza-Fiduciaria-logo.png'
            ></v-img>
          </v-col>
        </div>
      </v-row>
        <v-alert
      type="error"
      dark
      icon="mdi-alert-circle-outline"
      prominent
    >
      Ups! Ocurrió un error al verificar su cuenta. Por favor contacte al administrador del sistema.
      <v-divider
        class="my-4 info"
        style="opacity: 0.22"
      ></v-divider>
      admin@mdzfidu.ar
    </v-alert>
  </v-container>
  </div>
</template>
<script>
export default {
};
</script>
